<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加网点</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
          <en-table-column label="操作" width="270">
            <template #default="{ row }: { row: EnocloudAdminDefinitions['CloudTenantDto'] }">
              <en-button type="primary" text @click="table.operation.qrcode.click(row)">活动二维码</en-button>
              <en-button type="primary" text @click="table.operation.setting.click(row)">系统设置</en-button>
            </template>
          </en-table-column>
          <en-table-column label="网点编号" prop="id">
            <template #default="{ row }: { row: EnocloudAdminDefinitions['CloudTenantDto'] }">
              <en-button type="primary" text @click="table.serialNo.click(row)">{{ row.id }}</en-button>
            </template>
          </en-table-column>
          <en-table-column label="网点全称" prop="name"></en-table-column>
          <en-table-column label="网点简称" prop="shortName"></en-table-column>
          <en-table-column label="联系人" prop="contact"></en-table-column>
          <en-table-column label="联系人手机" prop="telephone"></en-table-column>
          <en-table-column label="备注" prop="comment"></en-table-column>
          <en-table-column label="状态" prop="status.message"></en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑网点' : '添加网点'" size="30%">
    <en-form
      :model="detail.form.data"
      :rules="detail.form.rules"
      :loading="detail.form.loading"
      :ref="setRef('detailForm')"
      class="grid grid-cols-2 gap-6"
    >
      <en-form-item label="网点编号" prop="serialNo">
        <en-input :disabled="Boolean(form.data.id)" v-model="detail.form.serialNo"></en-input>
      </en-form-item>
      <en-form-item label="网点全称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="网点简称" prop="shortName">
        <en-input v-model="detail.form.data.shortName"></en-input>
      </en-form-item>
      <en-form-item label="联系人：" prop="contact">
        <en-input v-model="detail.form.data.contact"></en-input>
      </en-form-item>
      <en-form-item label="联系人手机：" prop="telephone">
        <en-input v-model="detail.form.data.telephone"></en-input>
      </en-form-item>
      <en-form-item label="所在地区">
        <cascader-area v-model="detail.form.data.area" class="w-full"></cascader-area>
      </en-form-item>
      <en-form-item label="最大连锁店数量" prop="maxBranchCount">
        <en-input v-model="detail.form.data.maxBranchCount"></en-input>
      </en-form-item>
      <en-form-item label="主营业务：" prop="primaryBusiness">
        <en-input v-model="detail.form.data.primaryBusiness"></en-input>
      </en-form-item>
      <en-form-item label="详细地址" prop="address">
        <en-input v-model="detail.form.data.address"></en-input>
      </en-form-item>
      <en-form-item label="经度" prop="longitude">
        <en-input v-model="detail.form.data.longitude"></en-input>
      </en-form-item>
      <en-form-item label="纬度" prop="latitude">
        <en-input v-model="detail.form.data.latitude"></en-input>
      </en-form-item>

      <en-form-item label="微信服务号关注URL">
        <en-input v-model="detail.form.specifiedWechatAppQrcode.content"></en-input>
      </en-form-item>
      <en-form-item label="状态" prop="status">
        <select-maintain
          v-model="detail.form.data.status"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['TNTSTAT'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="商户号" prop="wechatMerchantId">
        <en-input v-model="detail.form.data.wechatMerchantId"></en-input>
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
      </en-form-item>
      <en-form-item label="logo" prop="logoUrl">
        <upload-maintain v-model="detail.form.data.logoUrl" dir="logo"></upload-maintain>
      </en-form-item>
    </en-form>
    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
  <en-drawer v-model="setting.visible" :title="'系统设置'" size="60%">
    <en-form :loading="drawerData.loading">
      <en-tabs v-model="tabs.active" tab-position="left">
        <en-tab-pane
          v-for="item of drawerData.attributeData"
          :key="item.id?.keyType?.code"
          :label="item.id?.keyType?.message"
          :name="item.id?.keyType?.code"
        >
          <en-scrollbar :height="height" view-class="grid grid-cols-3 gap-x-6">
            <en-form-item v-for="formItem of item.items" :label="formItem.id?.message" v-if="store.user.tenant?.id === 'ENOCH'">
              <template v-if="formItem.id?.valueType === 'PICTURE'">
                <upload-maintain v-model="formItem.value"></upload-maintain>
              </template>
              <template v-else-if="formItem.id?.valueType === 'NUMBER'">
                <en-input v-model="formItem.value"></en-input>
              </template>
              <template v-else-if="formItem.id?.valueType === 'DATE'">
                <en-date-picker v-model="formItem.value"></en-date-picker>
              </template>
              <template v-else-if="formItem.id?.valueType === 'TIME'">
                <en-date-picker v-model="formItem.value"></en-date-picker>
              </template>
              <template v-else-if="formItem.id?.valueType === 'DATETIME'">
                <en-date-picker v-model="formItem.value"></en-date-picker>
              </template>
              <template v-else-if="formItem.id?.valueType === 'REGION'">
                <span>Todo</span>
              </template>
              <template v-else-if="formItem.id?.valueType === 'FLAG'">
                <select-maintain
                  v-model="formItem.value"
                  :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                  :props="{ label: 'message', value: 'code' }"
                  class="w-full"
                ></select-maintain>
              </template>
              <template v-else-if="formItem.id?.valueType === 'CUSTOMER'">
                <select-maintain
                  v-model="formItem.value"
                  :ajax="{ action: 'GET /enocloud/common/customer', params: (params, value) => (params.payload = { name: value, type: 'B' }) }"
                  :props="{ label: 'name', value: '' }"
                  value-key="id"
                  remote
                  class="w-full"
                ></select-maintain>
              </template>

              <template v-else="formItem.id?.description">
                <span class="text-xs text-primary">{{ formItem.id?.description }}</span>
              </template>

              <template v-if="formItem.id?.code === 'ACTCSTCSA'">
                <span class="text-xs text-red-500">说明：活跃期客户设置需要同时满足未到店不可超出多少天数、到店次数、消费金额三个条件</span>
              </template>

              <template v-if="formItem.id?.code === 'LSCSTDPTD'">
                <span class="text-xs text-red-500">摇摆期客户：所有客户-活跃-流失-无消费</span>
              </template>

              <template v-if="formItem.id?.code === 'LSCSTDPTD'">
                <span class="text-xs text-red-500">无消费客户：没有创建过维修工单的客户</span>
              </template>
            </en-form-item>
          </en-scrollbar>
        </en-tab-pane>
      </en-tabs>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { h } from 'vue'
import { EnMessageBox } from '@enocloud/components'
import { uniqBy } from 'lodash-es'
const clientData = (allArea: any, data: EnocloudAdminDefinitions['AreaDto']) => {
  allArea.unshift(data)
  if (data.parent) {
    clientData(allArea, data.parent)
  }
  return allArea
}
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      tabs: {
        active: { code: 'CHAINMNG' }
      },
      form: {
        is: 'form',
        data: {
          id: '',
          name: '',
          shortName: '',
          contact: '',
          telephone: '',
          statusCode: '',
          comment: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/admin/tenant',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          qrcode: {
            action: 'GET /enocloud/security/wechat/c/qrcode'
          }
        },
        children: {
          operation: {
            qrcode: {
              async click(row: EnocloudAdminDefinitions['CloudTenantDto']) {
                const res = await this.table.qrcode({ payload: { tenantId: row.id } })
                return EnMessageBox({
                  title: row.name,
                  center: true,
                  showConfirmButton: false,
                  message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
                })
              }
            },
            setting: {
              click(row: EnocloudAdminDefinitions['CloudTenantDto']) {
                this.setting.form.init()
                this.setting.visible = true
              }
            }
          }
        },
        serialNo: {
          async click(row: EnocloudAdminDefinitions['CloudTenantDto']) {
            this.detail.form.init()
            this.detail.form.data.id = row.id
            await this.detail.form.get()
            this.detail.visible = true
          }
        }
      },
      drawerData: {
        ajax: {
          get: {
            action: 'GET /enocloud/admin/branch/attribute',
            data: 'array',
            loading: true
          },
          submit: {
            action: 'PUT /enocloud/admin/branch/attribute',
            loading: true,
            params(params) {
              params.data = this.drawerData.data
            }
          }
        },
        computed: {
          attributeData() {
            return uniqBy(this.drawerData.data, (item) => item.id?.keyType?.code).map((item) => {
              const res: AttributeData = Object.assign({}, item)
              res.items = this.drawerData.data.filter((d) => d.id?.keyType?.code === item.id?.keyType?.code)
              return res
            })
          }
        },
        children: {
          lookup: {
            paths(formItem: SystemAttributeDto) {
              switch (formItem.id?.code) {
                case 'PTSVTPLT':
                  return ['PTSVTPLT']
                case 'PTSVASNT':
                  return ['PTSVASTPLT']
                case 'ISRSTLTMP':
                  return ['ISTMPTYPE']
                case 'PTPUCTPLT':
                case 'PTSALTPLT':
                  return [formItem.id?.code]
                default:
                  return [formItem.id?.valueType]
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            rules: {
              serialNo: [{ required: true, message: '请填写网点编号' }],
              name: [{ required: true, message: '请填写网点全称' }],
              shortName: [{ required: true, message: '请填写网点简称' }],
              taxIdNumber: [{ required: true, message: '请填写税号' }],
              contact: [{ required: true, message: '请填写联系人' }],
              telephone: [{ required: true, message: '请填写手机号' }],
              allArea: [{ required: true, message: '请填写所在地区' }]
            },
            data: {
              id: '',
              serialNo: '',
              name: '',
              shortName: '',
              maxBranchCount: 1,
              primaryBusiness: '',
              contact: '',
              telephone: '',
              addressCodes: [],
              area: {},
              allArea: [],
              address: '',
              longitude: '',
              latitude: '',
              specifiedWechatAppQrcode: {
                content: ''
              },
              taxIdNumber: '',
              logoUrl: '',
              status: {
                code: 'A'
              },
              comment: '',
              merchantName: '',
              wechatMerchantId: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/admin/tenant/:tenantId',
                data: 'object',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/admin/tenant',
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/admin/tenant',
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            computed: {
              serialNo() {
                return this.detail.form.data.id
              },
              specifiedWechatAppQrcode() {
                return (
                  this.detail.form.data.specifiedWechatAppQrcode || {
                    content: ''
                  }
                )
              },
              allArea() {
                this.detail.form.data.allArea = []
                if (this.detail.form.data.area && this.detail.form.data.area.id) {
                  this.detail.form.data.allArea = clientData(this.detail.form.data.allArea, this.detail.form.data.area)
                } else {
                  this.detail.form.data.allArea = []
                }
                return this.detail.form.data.allArea
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                this.detail.form.data.serialNo = this.detail.form.serialNo
                this.detail.form.data.specifiedWechatAppQrcode = this.detail.form.specifiedWechatAppQrcode
                this.detail.form.data.allArea = this.detail.form.allArea
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      },
      setting: {
        visible: false,
        children: {
          form: {
            is: 'form',
            rules: {},
            data: {},
            ajax: {
              get: {
                action: 'GET /enocloud/admin/tenant/:tenantId/attribute',
                data: 'object',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },

              update: {
                action: 'PUT /enocloud/admin/tenant/:tenantId/attribute',
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            computed: {
              serialNo() {
                return this.detail.form.data.id
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                this.detail.form.data.serialNo = this.detail.form.serialNo
                this.detail.form.data.specifiedWechatAppQrcode = this.detail.form.specifiedWechatAppQrcode
                this.detail.form.data.allArea = this.detail.form.allArea
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
    this.drawerData.get()
    // this.tabs.active = this.drawerData.AttributeData[0].id?.keyType?.code!
  }
})
</script>
